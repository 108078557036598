<script setup lang="ts">
useHead({
  titleTemplate: '%s',
  title: 'STORM',
  meta: [
    {
      name: 'apple-mobile-web-app-title',
      content: 'STORM'
    },
    {
      name: 'apple-mobile-web-app-capable',
      content: 'yes'
    },
    {
      name: 'apple-mobile-web-app-status-bar-style',
      content: 'black-translucent'
    },
  ],
  link: [
    {
      rel: 'apple-touch-icon',
      type: 'image/png',
      sizes: '180x180',
      href: '/icons/apple-touch-icon.png'
    },
    {
      rel: 'apple-touch-icon',
      type: 'image/png',
      sizes: '64x64',
      href: '/icons/icon_64x64.png'
    },
    {
      rel: 'apple-touch-icon',
      type: 'image/png',
      sizes: '144x144',
      href: '/icons/icon_144x144.png'
    },
    {
      rel: 'apple-touch-icon',
      type: 'image/png',
      sizes: '192x192',
      href: '/icons/icon_192x192.png'
    },
    {
      rel: 'apple-touch-icon',
      type: 'image/png',
      sizes: '512x512',
      href: '/icons/icon_512x512.png'
    },
  ]
})

const {
  cookiesEnabled,
  cookiesEnabledIds,
  isConsentGiven,
  isModalActive,
  moduleOptions,
} = useCookieControl()

// example: react to a cookie being accepted
watch(
  () => cookiesEnabledIds.value,
  (current, previous) => {
    if (
      !previous?.includes('google-analytics') &&
      current?.includes('google-analytics')
    ) {
      // cookie with id `google-analytics` got added
      window.location.reload() // placeholder for your custom change handler
    }
  },
  { deep: true },
)
</script>

<template>
  <CookieControl locale="en" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
