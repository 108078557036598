import validate from "/home/runner/work/storm/storm/node_modules/.pnpm/nuxt@3.11.2_@azure+cosmos@4.0.1-alpha.20240402.1_@opentelemetry+api@1.8.0_@unocss+reset@0.60._ey3m5clonoptaox4xmjsslbue4/node_modules/nuxt/dist/pages/runtime/validate.js";
import client_45auth from "/home/runner/work/storm/storm/node_modules/.pnpm/@hebilicious+authjs-nuxt@0.3.5_@auth+core@0.17.0_nuxt@3.11.2_rollup@4.17.2/node_modules/@hebilicious/authjs-nuxt/dist/runtime/middleware/client-auth.mjs";
import manifest_45route_45rule from "/home/runner/work/storm/storm/node_modules/.pnpm/nuxt@3.11.2_@azure+cosmos@4.0.1-alpha.20240402.1_@opentelemetry+api@1.8.0_@unocss+reset@0.60._ey3m5clonoptaox4xmjsslbue4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  client_45auth,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/runner/work/storm/storm/node_modules/.pnpm/@hebilicious+authjs-nuxt@0.3.5_@auth+core@0.17.0_nuxt@3.11.2_rollup@4.17.2/node_modules/@hebilicious/authjs-nuxt/dist/runtime/middleware/auth.mjs"),
  "guest-only": () => import("/home/runner/work/storm/storm/node_modules/.pnpm/@hebilicious+authjs-nuxt@0.3.5_@auth+core@0.17.0_nuxt@3.11.2_rollup@4.17.2/node_modules/@hebilicious/authjs-nuxt/dist/runtime/middleware/guest-only.mjs")
}