import { default as __prerender_gallery__6GffiRlIL1Meta } from "/home/runner/work/storm/storm/pages/__prerender_gallery__.vue?macro=true";
import { default as aboutcRWolKRNjSMeta } from "/home/runner/work/storm/storm/pages/about.vue?macro=true";
import { default as careers8YtykEHQ2aMeta } from "/home/runner/work/storm/storm/pages/careers.vue?macro=true";
import { default as contact0nYeXuS3LGMeta } from "/home/runner/work/storm/storm/pages/contact.vue?macro=true";
import { default as environmentdpBr5WkyxRMeta } from "/home/runner/work/storm/storm/pages/environment.vue?macro=true";
import { default as how_45we_45workcpd9GfRUwvMeta } from "/home/runner/work/storm/storm/pages/how-we-work.vue?macro=true";
import { default as indexDaR0UVUYpRMeta } from "/home/runner/work/storm/storm/pages/index.vue?macro=true";
import { default as investor_45informationS1oryZdjBdMeta } from "/home/runner/work/storm/storm/pages/investor-information.vue?macro=true";
import { default as _91_46_46_46slug_93VsiyQoRKJmMeta } from "/home/runner/work/storm/storm/pages/news/[...slug].vue?macro=true";
import { default as index9YLRlC2ZNbMeta } from "/home/runner/work/storm/storm/pages/news/index.vue?macro=true";
import { default as partners_45and_45suppliersAWg5JCsYetMeta } from "/home/runner/work/storm/storm/pages/partners-and-suppliers.vue?macro=true";
import { default as presspWL8Si6o25Meta } from "/home/runner/work/storm/storm/pages/press.vue?macro=true";
import { default as privacy_45policyKWxeyvZLDDMeta } from "/home/runner/work/storm/storm/pages/privacy-policy.vue?macro=true";
import { default as privacy_45preferencesSmwsYyMiJbMeta } from "/home/runner/work/storm/storm/pages/privacy-preferences.vue?macro=true";
import { default as _91product_93QuwbaT0EQZMeta } from "/home/runner/work/storm/storm/pages/products/[group]/[product].vue?macro=true";
import { default as indexpnRYBjWr6IMeta } from "/home/runner/work/storm/storm/pages/products/[group]/index.vue?macro=true";
import { default as indexo9YWsSWC0PMeta } from "/home/runner/work/storm/storm/pages/products/index.vue?macro=true";
import { default as teamET3beYaIKZMeta } from "/home/runner/work/storm/storm/pages/team.vue?macro=true";
import { default as terms_45of_45usekBQsseN5TFMeta } from "/home/runner/work/storm/storm/pages/terms-of-use.vue?macro=true";
import { default as testliABkjyHTTMeta } from "/home/runner/work/storm/storm/pages/test.vue?macro=true";
import { default as why_45storm11dhpB8XL1Meta } from "/home/runner/work/storm/storm/pages/why-storm.vue?macro=true";
export default [
  {
    name: __prerender_gallery__6GffiRlIL1Meta?.name ?? "__prerender_gallery__",
    path: __prerender_gallery__6GffiRlIL1Meta?.path ?? "/__prerender_gallery__",
    meta: __prerender_gallery__6GffiRlIL1Meta || {},
    alias: __prerender_gallery__6GffiRlIL1Meta?.alias || [],
    redirect: __prerender_gallery__6GffiRlIL1Meta?.redirect,
    component: () => import("/home/runner/work/storm/storm/pages/__prerender_gallery__.vue").then(m => m.default || m)
  },
  {
    name: aboutcRWolKRNjSMeta?.name ?? "about",
    path: aboutcRWolKRNjSMeta?.path ?? "/about",
    meta: aboutcRWolKRNjSMeta || {},
    alias: aboutcRWolKRNjSMeta?.alias || [],
    redirect: aboutcRWolKRNjSMeta?.redirect,
    component: () => import("/home/runner/work/storm/storm/pages/about.vue").then(m => m.default || m)
  },
  {
    name: careers8YtykEHQ2aMeta?.name ?? "careers",
    path: careers8YtykEHQ2aMeta?.path ?? "/careers",
    meta: careers8YtykEHQ2aMeta || {},
    alias: careers8YtykEHQ2aMeta?.alias || [],
    redirect: careers8YtykEHQ2aMeta?.redirect,
    component: () => import("/home/runner/work/storm/storm/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: contact0nYeXuS3LGMeta?.name ?? "contact",
    path: contact0nYeXuS3LGMeta?.path ?? "/contact",
    meta: contact0nYeXuS3LGMeta || {},
    alias: contact0nYeXuS3LGMeta?.alias || [],
    redirect: contact0nYeXuS3LGMeta?.redirect,
    component: () => import("/home/runner/work/storm/storm/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: environmentdpBr5WkyxRMeta?.name ?? "environment",
    path: environmentdpBr5WkyxRMeta?.path ?? "/environment",
    meta: environmentdpBr5WkyxRMeta || {},
    alias: environmentdpBr5WkyxRMeta?.alias || [],
    redirect: environmentdpBr5WkyxRMeta?.redirect,
    component: () => import("/home/runner/work/storm/storm/pages/environment.vue").then(m => m.default || m)
  },
  {
    name: how_45we_45workcpd9GfRUwvMeta?.name ?? "how-we-work",
    path: how_45we_45workcpd9GfRUwvMeta?.path ?? "/how-we-work",
    meta: how_45we_45workcpd9GfRUwvMeta || {},
    alias: how_45we_45workcpd9GfRUwvMeta?.alias || [],
    redirect: how_45we_45workcpd9GfRUwvMeta?.redirect,
    component: () => import("/home/runner/work/storm/storm/pages/how-we-work.vue").then(m => m.default || m)
  },
  {
    name: indexDaR0UVUYpRMeta?.name ?? "index",
    path: indexDaR0UVUYpRMeta?.path ?? "/",
    meta: indexDaR0UVUYpRMeta || {},
    alias: indexDaR0UVUYpRMeta?.alias || [],
    redirect: indexDaR0UVUYpRMeta?.redirect,
    component: () => import("/home/runner/work/storm/storm/pages/index.vue").then(m => m.default || m)
  },
  {
    name: investor_45informationS1oryZdjBdMeta?.name ?? "investor-information",
    path: investor_45informationS1oryZdjBdMeta?.path ?? "/investor-information",
    meta: investor_45informationS1oryZdjBdMeta || {},
    alias: investor_45informationS1oryZdjBdMeta?.alias || [],
    redirect: investor_45informationS1oryZdjBdMeta?.redirect,
    component: () => import("/home/runner/work/storm/storm/pages/investor-information.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93VsiyQoRKJmMeta?.name ?? "news-slug",
    path: _91_46_46_46slug_93VsiyQoRKJmMeta?.path ?? "/news/:slug(.*)*",
    meta: _91_46_46_46slug_93VsiyQoRKJmMeta || {},
    alias: _91_46_46_46slug_93VsiyQoRKJmMeta?.alias || [],
    redirect: _91_46_46_46slug_93VsiyQoRKJmMeta?.redirect,
    component: () => import("/home/runner/work/storm/storm/pages/news/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index9YLRlC2ZNbMeta?.name ?? "news",
    path: index9YLRlC2ZNbMeta?.path ?? "/news",
    meta: index9YLRlC2ZNbMeta || {},
    alias: index9YLRlC2ZNbMeta?.alias || [],
    redirect: index9YLRlC2ZNbMeta?.redirect,
    component: () => import("/home/runner/work/storm/storm/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: partners_45and_45suppliersAWg5JCsYetMeta?.name ?? "partners-and-suppliers",
    path: partners_45and_45suppliersAWg5JCsYetMeta?.path ?? "/partners-and-suppliers",
    meta: partners_45and_45suppliersAWg5JCsYetMeta || {},
    alias: partners_45and_45suppliersAWg5JCsYetMeta?.alias || [],
    redirect: partners_45and_45suppliersAWg5JCsYetMeta?.redirect,
    component: () => import("/home/runner/work/storm/storm/pages/partners-and-suppliers.vue").then(m => m.default || m)
  },
  {
    name: presspWL8Si6o25Meta?.name ?? "press",
    path: presspWL8Si6o25Meta?.path ?? "/press",
    meta: presspWL8Si6o25Meta || {},
    alias: presspWL8Si6o25Meta?.alias || [],
    redirect: presspWL8Si6o25Meta?.redirect,
    component: () => import("/home/runner/work/storm/storm/pages/press.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyKWxeyvZLDDMeta?.name ?? "privacy-policy",
    path: privacy_45policyKWxeyvZLDDMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyKWxeyvZLDDMeta || {},
    alias: privacy_45policyKWxeyvZLDDMeta?.alias || [],
    redirect: privacy_45policyKWxeyvZLDDMeta?.redirect,
    component: () => import("/home/runner/work/storm/storm/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45preferencesSmwsYyMiJbMeta?.name ?? "privacy-preferences",
    path: privacy_45preferencesSmwsYyMiJbMeta?.path ?? "/privacy-preferences",
    meta: privacy_45preferencesSmwsYyMiJbMeta || {},
    alias: privacy_45preferencesSmwsYyMiJbMeta?.alias || [],
    redirect: privacy_45preferencesSmwsYyMiJbMeta?.redirect,
    component: () => import("/home/runner/work/storm/storm/pages/privacy-preferences.vue").then(m => m.default || m)
  },
  {
    name: _91product_93QuwbaT0EQZMeta?.name ?? "products-group-product",
    path: _91product_93QuwbaT0EQZMeta?.path ?? "/products/:group()/:product()",
    meta: _91product_93QuwbaT0EQZMeta || {},
    alias: _91product_93QuwbaT0EQZMeta?.alias || [],
    redirect: _91product_93QuwbaT0EQZMeta?.redirect,
    component: () => import("/home/runner/work/storm/storm/pages/products/[group]/[product].vue").then(m => m.default || m)
  },
  {
    name: indexpnRYBjWr6IMeta?.name ?? "products-group",
    path: indexpnRYBjWr6IMeta?.path ?? "/products/:group()",
    meta: indexpnRYBjWr6IMeta || {},
    alias: indexpnRYBjWr6IMeta?.alias || [],
    redirect: indexpnRYBjWr6IMeta?.redirect,
    component: () => import("/home/runner/work/storm/storm/pages/products/[group]/index.vue").then(m => m.default || m)
  },
  {
    name: indexo9YWsSWC0PMeta?.name ?? "products",
    path: indexo9YWsSWC0PMeta?.path ?? "/products",
    meta: indexo9YWsSWC0PMeta || {},
    alias: indexo9YWsSWC0PMeta?.alias || [],
    redirect: indexo9YWsSWC0PMeta?.redirect,
    component: () => import("/home/runner/work/storm/storm/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: teamET3beYaIKZMeta?.name ?? "team",
    path: teamET3beYaIKZMeta?.path ?? "/team",
    meta: teamET3beYaIKZMeta || {},
    alias: teamET3beYaIKZMeta?.alias || [],
    redirect: teamET3beYaIKZMeta?.redirect,
    component: () => import("/home/runner/work/storm/storm/pages/team.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45usekBQsseN5TFMeta?.name ?? "terms-of-use",
    path: terms_45of_45usekBQsseN5TFMeta?.path ?? "/terms-of-use",
    meta: terms_45of_45usekBQsseN5TFMeta || {},
    alias: terms_45of_45usekBQsseN5TFMeta?.alias || [],
    redirect: terms_45of_45usekBQsseN5TFMeta?.redirect,
    component: () => import("/home/runner/work/storm/storm/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: testliABkjyHTTMeta?.name ?? "test",
    path: testliABkjyHTTMeta?.path ?? "/test",
    meta: testliABkjyHTTMeta || {},
    alias: testliABkjyHTTMeta?.alias || [],
    redirect: testliABkjyHTTMeta?.redirect,
    component: () => import("/home/runner/work/storm/storm/pages/test.vue").then(m => m.default || m)
  },
  {
    name: why_45storm11dhpB8XL1Meta?.name ?? "why-storm",
    path: why_45storm11dhpB8XL1Meta?.path ?? "/why-storm",
    meta: why_45storm11dhpB8XL1Meta || {},
    alias: why_45storm11dhpB8XL1Meta?.alias || [],
    redirect: why_45storm11dhpB8XL1Meta?.redirect,
    component: () => import("/home/runner/work/storm/storm/pages/why-storm.vue").then(m => m.default || m)
  }
]