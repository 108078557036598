import revive_payload_client_ChagI7ifsg from "/home/runner/work/storm/storm/node_modules/.pnpm/nuxt@3.11.2_@azure+cosmos@4.0.1-alpha.20240402.1_@opentelemetry+api@1.8.0_@unocss+reset@0.60._ey3m5clonoptaox4xmjsslbue4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Y8T9QJUJfb from "/home/runner/work/storm/storm/node_modules/.pnpm/nuxt@3.11.2_@azure+cosmos@4.0.1-alpha.20240402.1_@opentelemetry+api@1.8.0_@unocss+reset@0.60._ey3m5clonoptaox4xmjsslbue4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_yVDsE2bVUm from "/home/runner/work/storm/storm/node_modules/.pnpm/nuxt@3.11.2_@azure+cosmos@4.0.1-alpha.20240402.1_@opentelemetry+api@1.8.0_@unocss+reset@0.60._ey3m5clonoptaox4xmjsslbue4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Et7DW994vm from "/home/runner/work/storm/storm/node_modules/.pnpm/nuxt@3.11.2_@azure+cosmos@4.0.1-alpha.20240402.1_@opentelemetry+api@1.8.0_@unocss+reset@0.60._ey3m5clonoptaox4xmjsslbue4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_OeCg4yBQNk from "/home/runner/work/storm/storm/node_modules/.pnpm/nuxt@3.11.2_@azure+cosmos@4.0.1-alpha.20240402.1_@opentelemetry+api@1.8.0_@unocss+reset@0.60._ey3m5clonoptaox4xmjsslbue4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/storm/storm/.nuxt/components.plugin.mjs";
import prefetch_client_dLD2RBG7IG from "/home/runner/work/storm/storm/node_modules/.pnpm/nuxt@3.11.2_@azure+cosmos@4.0.1-alpha.20240402.1_@opentelemetry+api@1.8.0_@unocss+reset@0.60._ey3m5clonoptaox4xmjsslbue4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w3krY91pl6 from "/home/runner/work/storm/storm/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@8.3.2_rollup@4.17.2_webpack@5.91.0/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.mjs";
import plugin_client_ME658G9d3x from "/home/runner/work/storm/storm/node_modules/.pnpm/nuxt-gtag@2.0.5_rollup@4.17.2/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import script_z4FA2lekBu from "/home/runner/work/storm/storm/node_modules/.pnpm/@nuxtjs+turnstile@0.7.1_rollup@4.17.2/node_modules/@nuxtjs/turnstile/dist/runtime/plugins/script.mjs";
import plugin_client_jNQC22GBbp from "/home/runner/work/storm/storm/node_modules/.pnpm/nuxt-viewport@2.1.5_rollup@4.17.2_vue@3.4.27/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import plugin_HNSDtujWzn from "/home/runner/work/storm/storm/node_modules/.pnpm/@hebilicious+authjs-nuxt@0.3.5_@auth+core@0.17.0_nuxt@3.11.2_rollup@4.17.2/node_modules/@hebilicious/authjs-nuxt/dist/runtime/plugin.mjs";
import chunk_reload_client_pgXMFrMZBj from "/home/runner/work/storm/storm/node_modules/.pnpm/nuxt@3.11.2_@azure+cosmos@4.0.1-alpha.20240402.1_@opentelemetry+api@1.8.0_@unocss+reset@0.60._ey3m5clonoptaox4xmjsslbue4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import Vue3Lottie_client_bMZiOS6AB0 from "/home/runner/work/storm/storm/plugins/Vue3Lottie.client.ts";
export default [
  revive_payload_client_ChagI7ifsg,
  unhead_Y8T9QJUJfb,
  router_yVDsE2bVUm,
  payload_client_Et7DW994vm,
  check_outdated_build_client_OeCg4yBQNk,
  components_plugin_KR1HBZs4kY,
  prefetch_client_dLD2RBG7IG,
  plugin_w3krY91pl6,
  plugin_client_ME658G9d3x,
  script_z4FA2lekBu,
  plugin_client_jNQC22GBbp,
  plugin_HNSDtujWzn,
  chunk_reload_client_pgXMFrMZBj,
  Vue3Lottie_client_bMZiOS6AB0
]